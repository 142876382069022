.modal {
  justify-content: center;
}

.header {
    background-color: #101810;
    color: #09bf00;
    font-size: 1.5rem;
}

.title {
    font-family: "commodore64";
    background-color: #101810;
    color: #09bf00;
    font-size: .9rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.body {
    font-family: "commodore64";
    background-color: #101810;
    color: #09bf00;
    font-size: .7rem;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.footer {
    background-color: #101810;
}

.footerCloseButton {
  padding: 10px;
  background: #EAC22B;
  border-radius: 8px;
  color: #09bf00;
}

.footerCloseButton:hover{
  color: white;
}

.buttonBackToMenu {
    /* background-color: #8679DE; */
    background-color: #101810;
    color: #09bf00;
    font-size: 0.8rem;
    padding: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.buttonBackToMenu:hover {
    /* background-color: #8679DE; */
    background-color: white;
}
