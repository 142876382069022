.loading {
    display: inline-block;
    position: relative;
}

.loading div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 8px;
    border-radius: 70%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading div:nth-child(1) {
    animation-delay: -0.30s;
}

.loading div:nth-child(2) {
    animation-delay: -0.2s;
}

.loading div:nth-child(3) {
    animation-delay: -0.10s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
