/* html, body, #app, #app>div {
    height: 100%
} */


.homepage {
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'commodore64';
    background-color: #20301f;
    color: #09bf00;
    overflow: hidden;
}

.homepage-new {
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'commodore64';
    background-color: #1a1a1a;
    overflow: hidden;
}

.mintpage {
    margin-left: 0;
    margin-right: 0;
    height: auto;
    width: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'commodore64';
    background-color: #20301f;
    color: #09bf00;
    overflow: hidden;
}

.submenu-mint {
    background-color: #101810;
    height: 85vh;
    width: 80vw;
    padding: 10px;
    /* overflow-y: scroll; */
}

.submenu-theater {
    background-color: #101810;
    border-radius: 5%;
    height: 85vh;
    width: 80vw;
    padding: 10px;
    /* overflow-y: scroll; */
}

.submenu-home {
    background-image: url('../images/background_mist.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    border-radius: 5%;
    height: 85vh;
    width: 80vw;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll; */
}

.submenu {
    background-color: #101810;
    border-radius: 5%;
    height: 85vh;
    width: 80vw;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* overflow-y: scroll; */
}


.header-home {
    font-size: 1em;
    margin-bottom: 30px;
}

.header-home2 {
    color: white;
    font-size: 1em;
    margin-bottom: 30px;
}

.header-catch-me {
    font-size: 30px;
    margin-bottom: -30px;
}

.about-projets {
    font-size: 30px;
    margin-top: 30px;
}



.eldritch-container{
  background-color: #101810;
  margin-top: 3rem;
  marging-right: auto;
  margin-left: auto;
  padding-left: 10rem;
  padding-right: 10rem;
  font-size: 1.25rem;
  text-align: left;
}

.eldritch-title{
  font-size: 1.8rem;
  padding-botton: 25px;
  font-weight: bold;
  text-transform: uppercase;
}

.eldritch-subtitle{
  font-style: italic;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.white{
  color: white !important;
}

.theater-subtitle{
  color: white !important;
  font-style: italic;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.divider{
  padding-bottom: 25px;
}

.dev-about {
    text-decoration: underline;
    font-weight: bold;
}

.intro {
    text-align: left;
    margin-top: 3rem;
    margin-bottom: -10px;
    font-size: 1rem;
    padding: 20px;
    text-transform: uppercase;
}

.scroll {
    overflow-y: scroll;
}

.buttonEffect {
    background-color: #483AAA;
    display: flex;
    margin-top: 30px;
    color: #ABABAB;
    font-size: 0.8rem;
    padding: 5px 20px;
}

.buttonBackToMenu {
    background-color: #101810;
    margin-top: 30px;
    color: #09bf00;
    font-size: 0.8rem;
    padding: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.buttonBackToMenu:hover {
    /* background-color: #8679DE; */
    background-color: white;
    cursor: pointer;
}

.buttonWeb3 {
    /* background-color: #8679DE; */
    display: inline-block;
    text-align: center;
    width: auto;
    background-color: #20301f;
    margin-top: 10px;
    color: #09bf00;
    font-size: 1.5rem;
    padding: 5px 20px;
    cursor: pointer;
}

.buttonWeb3:hover {
    /* background-color: #8679DE; */
    background-color: white;
    cursor: pointer;
}



.buttonNoScreen {
    /* background-color: #8679DE; */
    background-color: #483AAA;
    margin-bottom: 30px;
    color: #ABABAB;
    font-size: 0.8rem;
    padding: 5px 20px;
    text-decoration: none;
}

.flex {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-row {
    justify-content: center;
    align-items: center;
}

.menu {
    color: #09bf00;
    margin: -10px;
    padding: 0;
    width: 100vw;
    font-size: 25px;
    text-decoration: none;
    list-style-type: none;
    animation: glitch-skew 7s 3;
}

.menu2 {
    color: white;
    margin: -10px;
    outline: none;
    text-decoration: none;
    padding: 0;
    width: 100vw;
    font-size: 25px;
    list-style-type: none;
    animation: glitch-skew 7s 3;
}

.yes-button {
text-align: center;
}

.link {
    text-decoration: none;
    color: #09bf00;
    outline: none;
}

li:focus {
    color: white;
    background-color: #2b2b2b;
    text-decoration: none;
  }

  .homelink {
      text-decoration: none;
      color: white;
      outline: none;
  }

  .homelink:focus {
      background-color: #2b2b2b;
      text-decoration: none;
    }


.ready {
    text-align: left;
    margin-top: 3rem;
    margin-bottom: -10px;
    font-size: 24px;
}

.readyspan {
    text-align: left;
    margin-top: 3rem;
    margin-bottom: -10px;
    font-size: 24px;
    color: white;
}


.menu-arrows {
    font-size: 40px;
}

.up-down {
    margin: 0;
    padding: 0;
    font-size: 1.15rem;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 950px;
}

.loadingInterlude {
    padding: 10px 40px;
    font-size: 40px;
    color: #09bf00;
    text-decoration: none;
    animation: glitch-skew 1s 1;
}

.loadingInterlude:hover {
    padding: 10px 40px;
    font-size: 40px;
    color: white;
    border-bottom: 5px dashed white;
}

.dev {
    font-size: 15px;
    margin-top: -10px;
}

.run {
    animation-delay: 48s;
    -webkit-animation-delay: 48s;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    font-size: 30px;
    color: #09bf00;
    text-decoration: none;
}

.run2 {
    animation-delay: 38s;
    -webkit-animation-delay: 38s;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    font-size: 30px;
    color: #09bf00;
    text-decoration: none;
}

.typing {
    content: '';
    height: 24px;
    position: absolute;
    width: 37.4ch;
    animation: typing 3s steps(37) 1s both, blink 1s;
    white-space: nowrap;
    overflow: hidden;
    border-right: 24px solid #09bf00;
}

.typing2 {
    content: '';
    height: 24px;
    position: absolute;
    width: 37.4ch;
    animation-delay: 15s;
    animation: typing 3s steps(37) 1s both, blink 1s step-end 5s;
    white-space: nowrap;
    overflow: hidden;
    border-right: 24px solid #09bf00;
}

.css-typing p {
  border-right: .15em solid #09bf00;
  font-size: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
}
.css-typing p:nth-child(1) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type 5s steps(40, end);
  animation: type 5s steps(40, end);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type2 5s steps(40, end);
  animation: type2 5s steps(40, end);
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(3) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type2 5s steps(20, end);
  animation: type2 5s steps(20, end);
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(4) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type2 5s steps(20, end);
  animation: type2 5s steps(20, end);
  -webkit-animation-delay: 22s;
  animation-delay: 22s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(5) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type2 5s steps(20, end);
  animation: type2 5s steps(20, end);
  -webkit-animation-delay: 27s;
  animation-delay: 27s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(6) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type2 5s steps(20, end);
  animation: type2 5s steps(20, end);
  -webkit-animation-delay: 32s;
  animation-delay: 32s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(7) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type2 5s steps(20, end);
  animation: type2 5s steps(20, end);
  -webkit-animation-delay: 37s;
  animation-delay: 37s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(8) {
  width: 42ch;
  opacity: 0;
  -webkit-animation: type3 5s steps(20, end);
  animation: type3 5s steps(20, end);
  -webkit-animation-delay: 42s;
  animation-delay: 42s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.Cell-on {
    width: 25px;
    height: 25px;

    border-radius: 50%;

    background-color: #09bf00;

    border: none;
    margin: 20px;

    box-shadow:
    0 0 22px rgb(18, 231, 231),
    0 0 42px rgb(18, 231, 231)


}

.Cell-on:hover {
    background-color: white;
}

.Cell-off {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: none;
    margin: 20px;
    background-color: rgb(55, 55, 55);
}

.Board {
    background-color: #101810;
    display:flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 2px;
    box-shadow:
        0 0 7px #09bf00,
        0 0 10px #09bf00

}

.Board-row {
    display: inline-block;
    width: auto;
}

.Board-hasWon {
    color: #09bf00;
    font-family: 'commodore64';
    font-size: 2.5rem;
    letter-spacing: 2px;
    margin-bottom: 0;
    padding: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
}

/* Animation property */
.edge {
 height: 500px;
}

.edge:hover{
  animation: wiggle 2s linear infinite;
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@keyframes type {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid #09bf00;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid #09bf00;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid #09bf00;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid #09bf00;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}


@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}


.c64-loading {
    width: 100%;
    opacity: 70%;
}

.see-more {
    font-size: 1.2rem;
    cursor: pointer;
    margin: 25px;
    text-align: left;
}

.see-more:hover {
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

.see-theater {
    font-size: 1.2rem;
    cursor: pointer;
    margin: 25px;
    text-align: center;
}

.see-theater:hover {
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    text-decoration: underline;
}

.react-player{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.textSizeTemp {
    font-size: 1.2rem;
}

.crt::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
}

.crt::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
}

.crt {
    animation: textShadow 1.6s infinite;
}

.image-self {
    display: flex;
    justify-content: center;
    width: 100px;
    /* height: 100px; */
    margin: -15px;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
}

.image-hide {
    animation: none;
    visibility: hidden;
}

/* .image-self:hover {
    display: flex;
    justify-content: center;
    width: 150px; */
/* height: 100px; */
/* margin: -15px;
    padding: 0;
    border-radius: 50%
} */

.x {
    animation: x 7s linear infinite alternate;
}

.y {
    animation: y 5s linear infinite alternate;
}

@keyframes x {
    100% {
        transform: translateX(calc(80vw - 100px));
    }
}

@keyframes y {
    100% {
        transform: translateY(calc(85vh - 100px));
    }
}

/* .fullPageColors {
    height: 100%;
    width: 100%;
}

.color1 {
    width: 100%;
    height: 6.25%;
    background-color: #000;
}

.color2 {
    width: 100%;
    height: 6.25%;
    background-color: #FFF;
}

.color3 {
    width: 100%;
    height: 6.25%;
    background-color: #894026;
}

.color4 {
    width: 100%;
    height: 6.25%;
    background-color: #7ABFC7;
}

.color5 {
    width: 100%;
    height: 6.25%;
    background-color: #8A46AE;
}

.color6 {
    width: 100%;
    height: 6.25%;
    background-color: #68A941;
}

.color7 {
    width: 100%;
    height: 6.25%;
    background-color: #3E31A2;
}

.color8 {
    width: 100%;
    height: 6.25%;
    background-color: #D0DC71;
}

.color9 {
    width: 100%;
    height: 6.25%;
    background-color: #905F25;
}

.color10 {
    width: 100%;
    height: 6.25%;
    background-color: #5C4700;
}

.color11 {
    width: 100%;
    height: 6.25%;
    background-color: #BB776D;
}

.color12 {
    width: 100%;
    height: 6.25%;
    background-color: #555555;
}

.color13 {
    width: 100%;
    height: 6.25%;
    background-color: #808080;
}

.color14 {
    width: 100%;
    height: 6.25%;
    background-color: #ACEA88;
}

.color15 {
    width: 100%;
    height: 6.25%;
    background-color: #7C70DA;
}

.color16 {
    width: 100%;
    height: 6.25%;
    background-color: #ABABAB;
} */

@keyframes glitch-skew {
    0% {
        transform: skew(0deg, 0deg);
    }

    48% {
        transform: skew(0deg, 0deg);
        filter: blur(0);
    }

    50% {
        transform: skew(-20deg, 0deg);
        filter: blur(4px);
    }

    52% {
        transform: skew(20deg, 0deg);
    }

    54% {
        transform: skew(0deg, 0deg);
        filter: blur(0);
    }

    100% {
        transform: skew(0deg, 0deg);
    }

}

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}

@keyframes textShadow {
    0% {
        text-shadow: 0.4389924193300864px 0 1px rgba(0, 30, 255, 0.5), -0.4389924193300864px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    5% {
        text-shadow: 2.7928974010788217px 0 1px rgba(0, 30, 255, 0.5), -2.7928974010788217px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    10% {
        text-shadow: 0.02956275843481219px 0 1px rgba(0, 30, 255, 0.5), -0.02956275843481219px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    15% {
        text-shadow: 0.40218538552878136px 0 1px rgba(0, 30, 255, 0.5), -0.40218538552878136px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    20% {
        text-shadow: 3.4794037899852017px 0 1px rgba(0, 30, 255, 0.5), -3.4794037899852017px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    25% {
        text-shadow: 1.6125630401149584px 0 1px rgba(0, 30, 255, 0.5), -1.6125630401149584px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    30% {
        text-shadow: 0.7015590085143956px 0 1px rgba(0, 30, 255, 0.5), -0.7015590085143956px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    35% {
        text-shadow: 3.896914047650351px 0 1px rgba(0, 30, 255, 0.5), -3.896914047650351px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    40% {
        text-shadow: 3.870905614848819px 0 1px rgba(0, 30, 255, 0.5), -3.870905614848819px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    45% {
        text-shadow: 2.231056963361899px 0 1px rgba(0, 30, 255, 0.5), -2.231056963361899px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    50% {
        text-shadow: 0.08084290417898504px 0 1px rgba(0, 30, 255, 0.5), -0.08084290417898504px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    55% {
        text-shadow: 2.3758461067427543px 0 1px rgba(0, 30, 255, 0.5), -2.3758461067427543px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    60% {
        text-shadow: 2.202193051050636px 0 1px rgba(0, 30, 255, 0.5), -2.202193051050636px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    65% {
        text-shadow: 2.8638780614874975px 0 1px rgba(0, 30, 255, 0.5), -2.8638780614874975px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    70% {
        text-shadow: 0.48874025155497314px 0 1px rgba(0, 30, 255, 0.5), -0.48874025155497314px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    75% {
        text-shadow: 1.8948491305757957px 0 1px rgba(0, 30, 255, 0.5), -1.8948491305757957px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    80% {
        text-shadow: 0.0833037308038857px 0 1px rgba(0, 30, 255, 0.5), -0.0833037308038857px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    85% {
        text-shadow: 0.09769827255241735px 0 1px rgba(0, 30, 255, 0.5), -0.09769827255241735px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    90% {
        text-shadow: 3.443339761481782px 0 1px rgba(0, 30, 255, 0.5), -3.443339761481782px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    95% {
        text-shadow: 2.1841838852799786px 0 1px rgba(0, 30, 255, 0.5), -2.1841838852799786px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }

    100% {
        text-shadow: 2.6208764473832513px 0 1px rgba(0, 30, 255, 0.5), -2.6208764473832513px 0 1px rgba(255, 0, 80, 0.3), 0 0 3px;
    }
}

@media (min-width:650px) and (max-width: 849px){
  .css-typing p {
    border-right: .15em solid #09bf00;
    font-size: .75rem;
    white-space: nowrap;
    overflow: hidden;
  }

  .eldritch-container{
    margin-top: 3rem;
    font-size: .75rem;
    text-align: left;
  }

  .run {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100vh;
      font-size: .85rem;
      color: #09bf00;
      text-decoration: none;
  }

  .run2 {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100vh;
      font-size: .85rem;
      color: #09bf00;
      text-decoration: none;
  }
}

@media (max-width: 649px){
  .buttonWeb3 {
      font-size: .5rem;
  }

  .see-more {
      font-size: .5rem;
  }

  .see-more:hover {
      font-size: .5rem;
  }

  .css-typing p {
    border-right: .15em solid #09bf00;
    font-size: .5rem;
    white-space: nowrap;
    overflow: hidden;
  }

  .eldritch-container{
    margin-top: 3rem;
    font-size: .5rem;
    text-align: left;
  }

  .run {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100vh;
      font-size: .75rem;
      color: #09bf00;
      text-decoration: none;
  }

  .run2 {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100vh;
      font-size: .75rem;
      color: #09bf00;
      text-decoration: none;
  }
  .menu {
      color: #09bf00;
      margin: -10px;
      padding: 0;
      width: 100vw;
      font-size: .6rem;
      text-decoration: none;
      list-style-type: none;
      animation: glitch-skew 7s 3;
  }
  .header-home {
      font-size: .7em;
      margin-bottom: 5px;
  }
  .up-down {
      margin: 0;
      padding: 0;
      font-size: .5rem;
  }
}
